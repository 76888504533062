import React, { useState, useContext, createContext, Suspense, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import appSyncConfig from './aws-exports';
import AWSAppSyncClient, { defaultDataIdFromObject } from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react';
import GoogleFontLoader from 'react-google-font-loader';
import WebFont from 'webfontloader';
import { useTranslation } from 'react-i18next';
import { ApolloProvider } from 'react-apollo';
import './App.css';
import QueryGetVendor from './graphql/QueryGetVendor';
import Amplify, { Auth, I18n, API, graphqlOperation } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import * as storage from './utils/storage';
import '@aws-amplify/ui/dist/style.css';
import awsConfig from './aws-exports';
Amplify.configure(awsConfig);


const PartiesToCome = React.lazy(() => import('./Screens/Events/PartiesToCome'));
//import PartiesToCome from './Screens/Events/PartiesToCome';
const Replay = React.lazy(() => import('./Screens/Events/Replay'));
//import Replay from './Screens/Events/Replay';

const baseUrlFont = 'https://bleeker-web.s3.us-east-2.amazonaws.com/fonts/';

export const vendorID = 'NC';
export const isWidget = {
  toggle: true,
};

export const customStyle = {
  logoHeight: '47px',
  fontFamily: 'Lato',
  titleWeight: '600',
  titleSize: '24px',
  primaryColor: '#000',
  hoverPrimaryColor: '#003366',
  lightPrimaryColor: '#ff9e9e',
  secondaryColor: '#cccccc',
  lightSecondaryColor: '#eeeeee',
  headerColor: '#fff',
  buttonRadius: '0px',
  buttonWeight: '400',
  buttonTransform: 'none',
  buttonColor: '#000',
  buttonBackground: '#fff',
  buttonBorder: '#000',
};

export const VendorDetails = createContext();

const MyContext = React.createContext();

const MyProvider = props => {
  const [menuOpenState, setMenuOpenState] = useState(true);

  return (
    <VendorDetails.Provider value={props.vendorID}>
      <MyContext.Provider
        value={{
          isMenuOpen: menuOpenState,
          toggleMenu: () => setMenuOpenState(!menuOpenState),
          stateChangeHandler: newState => setMenuOpenState(newState.isOpen),
        }}
      >
        {props.children}
      </MyContext.Provider>
    </VendorDetails.Provider>
  );
};


I18n.setLanguage(storage.local.getItem('lang') === null ? 'fr' : storage.local.getItem('lang'));


const setHead = () => {
  var css =
    "@font-face {font-family: '" + customStyle.fontFamily + "';src: url(" + customStyle.urlFont + ') format("woff2");}';
  css +=
    "@font-face {font-family: '" +
    customStyle.fontFamilyTitle +
    "';src: url(" +
    customStyle.urlFontTitle +
    ') format("woff2");}';
  css +=
    "@font-face {font-family: '" +
    customStyle.fontFamilyButton +
    "';src: url(" +
    customStyle.urlFontButton +
    ') format("woff2");}';
  var head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  head.appendChild(style);
  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
};

const setStyle = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const vendorID = urlParams.get('vendorID');
  const widget = urlParams.get('widget') ? urlParams.get('widget') : true;
  isWidget.toggle = widget;
  if (vendorID !== null) {
    const result = await API.graphql(graphqlOperation(QueryGetVendor, { vendorID: vendorID }));
    storage.local.setItem('languages', result.data.getVendor.languages);
    if (result.data.getVendor.style) {
      const styleBrand = JSON.parse(result.data.getVendor.style);
      customStyle.logoHeight = styleBrand[0].logoHeight;
      customStyle.fontFamily = styleBrand[0].fontFamily;
      customStyle.fontFamilyTitle = styleBrand[0].fontFamilyTitle
        ? styleBrand[0].fontFamilyTitle
        : styleBrand[0].fontFamily;
      customStyle.fontFamilyButton = styleBrand[0].fontFamilyButton
        ? styleBrand[0].fontFamilyButton
        : styleBrand[0].fontFamily;
      customStyle.fontType = styleBrand[0].fontType;

      customStyle.titleTransform = styleBrand[0].titleTransform;
      customStyle.isFontCustom = styleBrand[0].isFontCustom || false;
      customStyle.contentSize = styleBrand[0].contentSize || '14px';
      customStyle.titleSize = styleBrand[0].titleSize || '22px';
      customStyle.titleWeight = styleBrand[0].titleWeight || '600';
      customStyle.progressBar = styleBrand[0].progressBar || '#fff';
      customStyle.primaryColor = styleBrand[0].primaryColor;
      customStyle.primaryBackground = styleBrand[0].primaryBackground || '#fff';
      customStyle.hoverPrimaryColor = styleBrand[0].hoverPrimaryColor;
      customStyle.lightPrimaryColor = styleBrand[0].lightPrimaryColor;
      customStyle.secondaryColor = styleBrand[0].secondaryColor;
      customStyle.lightSecondaryColor = styleBrand[0].lightSecondaryColor;
      customStyle.headerColor = styleBrand[0].headerColor;
      customStyle.buttonRadius = styleBrand[0].buttonRadius;
      customStyle.buttonWeight = styleBrand[0].buttonWeight;
      customStyle.buttonTransform = styleBrand[0].buttonTransform;
      customStyle.buttonColor = styleBrand[0].buttonColor;
      customStyle.buttonBackground = styleBrand[0].buttonBackground;
      customStyle.buttonBorder = styleBrand[0].buttonBorder;
      customStyle.cardRadius = styleBrand[0].cardRadius || '4px';
      customStyle.channelBkg = styleBrand[0].channelBkg || 'rgba(0,0,0,0.25)';
      customStyle.imageRadius = styleBrand[0].imageRadius || 0;
      customStyle.textColor = styleBrand[0].textColor || '#000';

      if (customStyle.isFontCustom) {
        customStyle.urlFont = baseUrlFont + customStyle.fontFamily + '.' + customStyle.fontType;
        customStyle.urlFontTitle = baseUrlFont + customStyle.fontFamilyTitle + '.' + customStyle.fontType;
        customStyle.urlFontButton = baseUrlFont + customStyle.fontFamilyButton + '.' + customStyle.fontType;
        setHead();
      }

      document.documentElement.style.setProperty('--logo-height-custom', customStyle.logoHeight);
      document.documentElement.style.setProperty('--font-family-custom', customStyle.fontFamily);
      document.documentElement.style.setProperty('--font-family-custom-title', customStyle.fontFamilyTitle);
      document.documentElement.style.setProperty('--font-family-custom-button', customStyle.fontFamilyButton);
      document.documentElement.style.setProperty('--font-family-custom-url', customStyle.urlFont);
      document.documentElement.style.setProperty('--title-size-custom', customStyle.titleSize);
      document.documentElement.style.setProperty('--content-size-custom', customStyle.contentSize);
      document.documentElement.style.setProperty('--title-transform-custom', customStyle.titleTransform);
      document.documentElement.style.setProperty('--title-weight-custom', customStyle.titleWeight);
      document.documentElement.style.setProperty('--primary-color', customStyle.primaryColor);
      document.documentElement.style.setProperty('--primary-background', customStyle.primaryBackground);
      document.documentElement.style.setProperty('--hover-primary-color', customStyle.hoverPrimaryColor);
      document.documentElement.style.setProperty('--light-primary-color', customStyle.lightPrimaryColor);
      document.documentElement.style.setProperty('--secondary-color', customStyle.secondaryColor);
      document.documentElement.style.setProperty('--light-secondary-color', customStyle.lightSecondaryColor);
      document.documentElement.style.setProperty('--header-custom', customStyle.headerColor);
      document.documentElement.style.setProperty('--button-radius-custom', customStyle.buttonRadius);
      document.documentElement.style.setProperty('--button-weight-custom', customStyle.buttonWeight);
      document.documentElement.style.setProperty('--button-transform-custom', customStyle.buttonTransform);
      document.documentElement.style.setProperty('--button-background', customStyle.buttonBackground);
      document.documentElement.style.setProperty('--button-color', customStyle.buttonColor);
      document.documentElement.style.setProperty('--button-border', customStyle.buttonBorder);
      document.documentElement.style.setProperty('--card-radius-custom', customStyle.cardRadius);
      document.documentElement.style.setProperty('--progress-bar-custom', customStyle.progressBar);
      document.documentElement.style.setProperty('--image-radius', customStyle.imageRadius);
      document.documentElement.style.setProperty('--text-color', customStyle.textColor);
    }

    if (customStyle.isFontCustom) {
      WebFont.load({
        custom: {
          families: [customStyle.fontFamily, customStyle.fontFamilyTitle, customStyle.fontFamilyButton],
        },
      });
    } else {
      WebFont.load({
        google: {
          families: [customStyle.fontFamily + ':300,400,700', 'sans-serif'],
        },
      });
    }
  }
};

const App = authState => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
        videoRef.current.pause();
    } else {
        videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
};

  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: customStyle.fontFamily,
            weights: [400],
          },
        ]}
      />
  <Switch>
        <Route path="/" exact component={PartiesToCome} />
        <Route path="/replay/:roomID" component={Replay} key={400} />
      </Switch>
    </>
  );
};

const client = new AWSAppSyncClient({
  url: appSyncConfig.aws_appsync_graphqlEndpoint,
  region: appSyncConfig.aws_appsync_region,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    apiKey: appSyncConfig.aws_appsync_apiKey,
  },
  disableOffline: true,
  cacheOptions: {
    dataIdFromObject: obj => {
      let id = defaultDataIdFromObject(obj);

      if (!id) {
        const { __typename: typename } = obj;
        switch (typename) {
          case 'Comment':
            return `${typename}:${obj.commentId}`;
          default:
            return id;
        }
      }
      return id;
    },
  },
});

const AuthStateApp = () => {
  const ctx = useContext(MyContext);
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const { t } = useTranslation();
  setStyle();
  React.useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAuthState(AuthState.SignedIn);
        setUser(user);
      } catch (err) {}
    }
    fetchData();
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const vendorID = urlParams.get('vendorID') ? isBase64(urlParams.get('vendorID')) : null;

  const widget = urlParams.get('widget') ? urlParams.get('widget') : false;
  isWidget.toggle = widget;

  return (
    <ApolloProvider client={client}>
    <Rehydrated loading={<span />}>
      <MyProvider vendorID={vendorID}>
        <Router>
          <div className="main-wrapper">
          <Suspense fallback = { <div></div> } >
               <App authState={authState} />
               </Suspense>
          </div>
        </Router>
      </MyProvider>
    </Rehydrated>
  </ApolloProvider>
  );
};

function isBase64(str) {
  try {
    if (btoa(atob(str)) == str) {
      return atob(str);
    } else {
      return str;
    }
  } catch (err) {
    return str;
  }
}

export default AuthStateApp;
