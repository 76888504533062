import gql from 'graphql-tag';

export default gql(`
query($vendorID: ID!) {
  getVendor(id: $vendorID) {
    name
    website
    websiteEN
    websitePreprod
    id
    maxusers
    maxduration
    gap
    style
    terms
    termsEN
    iframe
    languages
    serviceNameFR
    serviceNameEN
    teamNameFR
    teamNameEN
    titleFR
    titleEN
    introEN
    introFR
    livestreaming
    experience
    maxguests
    steps
    chooseStore
    chooseHost
    extraQuestions
    custom_text
    stripeKey
  }
}`);
