import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import './i18n';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import theme from './theme';


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
        <App />
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
